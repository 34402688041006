/* --------- Card Animation ---------- */
.card:not(:empty):hover,
.card:not(:empty):focus {
  z-index: 1;
  color: #fff;
  background: #ea124f;
  opacity: 1;
  /* transform: scale(1.15) rotateZ(20deg); */
  cursor: pointer;
  
}
.card:not(:empty):hover:after,
.card:not(:empty):focus:after {
  opacity: 1;
}
.card:not(:empty):hover:before,
.card:not(:empty):focus:before {
  opacity: 0;
}

.card {
  font-weight: bold;
  padding: 10px;
  background: #fcc99e;
  border-radius: 0.7em;
  opacity: 0.6;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  /* transition: all 0.4s cubic-bezier(0.33, 1, 0.68, 1); */
  /* transition-property: background, transform, color, opacity; */
}
.card:not(:empty):before {
  box-shadow: -2px 2px 8px 2px rgba(0, 0, 0, 0.2);
}
.card:empty {
  opacity: 0.3;
}
.card:before, .card:after {
  content: "";
  position: absolute;
  border-radius: 0.7em;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.4s cubic-bezier(0.33, 1, 0.68, 1);
}
.card:after {
  box-shadow: -20px 20px 12px 6px rgba(0, 0, 0, 0.2);
  opacity: 0;
}

/* --------- Grid ---------- */
.grid {
  width: 100%;
  height: 100vh;
  padding: 20px;
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 20px;
}

.item1 {
  grid-area: 1/1/3/3;
}

.item2 {
  font-weight: bolder;
  color: white;
  grid-area: 3/3/4/4;
}
.item2.card:hover, .item2.card:focus {
  background: #00005c;
}

.item3 {
  color: white;
  grid-area: 4/3/4/4;
}

.item4 {
  color: white;
  grid-area: 1/3/2/5;
}

.item5 {
  color: white;
  grid-area: 2/4/3/5;
}
.item12 {
  color: #fff;
  grid-area: 2/4/2/3;
}
.item6 {
  color: white;
  grid-area: 3/4/4/5;
}

.item7 {
  color: white;
  grid-area: 3/4/5/5;
}

.item8 {
  color: white;
  grid-area: 1/5/2/6;
}
.item8.card:hover, .item8.card:focus {
  background: #f57b51;
}

.item9 {
  color: white;
  grid-area: 2/5/3/6;
}

.item10 {
  color: white;
  grid-area: 3/5/4/6;
}

.item11 {
  color: white;
  grid-area: 4/5/5/6;
}
.item11.card:hover, .item11.card:focus {
  background: #00a8cc;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/*--------- Layout -------- */
*,
*:before,
*:after {
  position: relative;
  box-sizing: border-box;
}


.card h1,
.card h2,
 .card h3,
.card h4,
.card h5 {
  /* margin: 2.75rem 0 1.05rem; */
  line-height: 1.2;
  font-family: "Robbins", sans-serif;
}

.card h1 {
  margin-top: 0;
  font-size: 3.052em;
}

.card h2 {
  font-size: 1em;
}

.content p {
  line-height: 1.3;
}

/* --------- Responsive ---------- */
@media only screen and (max-width: 600px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, minmax(auto, 150px));
    height: auto;
    /* overflow-y: scroll; */
  }

  .item1 {
    grid-area: 1/1/2/3;
  }

  .card {
    grid-area: auto;
    text-align: left;
    width: 150px;
  }
  .card:empty {
    display: none;
  }
  .item8 p{
    font-size: 13px;
  }
}