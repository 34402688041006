/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Poppins", sans-serif;
} */

.container .card {
  position: relative;
  width: 275px;
  height: 400px;
  background: white;
  border-radius: 20px;
  overflow: hidden;
  border: 1px grey solid;
  color: black;
}

.container .card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #3b2267;
  clip-path: circle(150px at 80% 20%);
  transition: 0.5s ease-in-out;
}

.container .card:hover:before {
  clip-path: circle(300px at 80% -20%);
}

.container .card:after {
  content: "Udaan";
  position: absolute;
  top: 50%;
  left: -5%;
  font-size: 6em;
  font-weight: 800;
  font-style: italic;
  opacity: 0.5;
  color: grey;
}

.container .card .imgBx {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10000;
  width: 100%;
  height: 220px;
  transition: 0.5s;
}

.container .card:hover .imgBx {
  top: 0%;
  transform: translateY(0%);
}

.container .card .imgBx img {
  position: absolute;
  top: 5%;
  left: 27%;

  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.container .card .contentBx {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  text-align: center;
  transition: 1s;
  z-index: 10;
}

.container .card:hover .contentBx {
  height: 150px;
}

.container .card .contentBx h2 {
  position: relative;
  font-weight: 600;
  /* letter-spacing: 1px; */
  font-size: 20px;

  margin: 0;
}

.container .card .contentBx .size,
.container .card .contentBx .color {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  padding-top: 0;
  padding-bottom: 0;
}

.container .card:hover .contentBx .size {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.5s;
}

.container .card:hover .contentBx .color {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.6s;
}

.container .card .contentBx .size h3 {
  color: white;
  font-weight: 300;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-right: 10px;
  background: linear-gradient(
    to right,
    #47484ec7,
    #276fad,
    #e24306db,
    #3e3a7ffa,
    #3b3952fa
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.container .card .contentBx .color h3 {
  color: black;
  font-weight: 300;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-right: 10px;
}
.container .card .contentBx .size span {
  width: 26px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  font-size: 14px;
  display: inline-block;
  color: #111;
  background: #fff;
  margin: 0 5px;
  transition: 0.5s;
  color: #111;
  border-radius: 4px;
  cursor: pointer;
}

.container .card .contentBx .size span:hover {
  background: #9bdc28;
}

.container .card .contentBx .color span {
  width: 20px;
  height: 20px;
  background: black;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}
.container .card .contentBx .a {
  /* display: inline-block; */
  /* padding: 10px 20px; */
  /* background: #fff; */
  border-radius: 4px;
  margin-top: 10px;
  text-decoration: none;
  font-weight: 600;
  color: #111;
  opacity: 0;
  transform: translateY(50px);
  transition: 0.5s;
  margin-top: 0;
}

.container .card:hover .contentBx .a {
  opacity: 1;
  transform: translateY(0px);
  transition-delay: 0.75s;
}
@media screen and (max-width: 600px) {
  .container .card {
    height: 350px;
    margin-top: 10px;
  }
}
