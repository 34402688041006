@media screen and (max-width: 600px) {
    .head {
        margin-left: 15px;
    }
}
.Card{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}
.head {
    /* background:linear-gradient(to right, #8e2de2, #4a00e0); */
    text-align: center;
    font-family: sans-serif;
    font-weight: bold;
    color: #121c72;
    background-color: #0093E9;
    background: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
    /* background: linear-gradient(to right, #4bc0c8, #c779d0, #feac5e); */
    -webkit-text-fill-color: transparent;
    background-clip: text;

}

.hr {
    background-color: white;
}