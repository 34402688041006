.Logo{
background-color: white; 
padding: 4px;
height: 100%;
box-sizing: border-box;
border-radius: 8px;
}

.Logo img{
    height: 100%;
}