.Cofounder{
    width: 100%;
    height: auto;
}

.Cofounder img{
    border: solid #121c79 4px;
    height: 200px;
    width: 200px;
    border-radius: 50%;
}

.paraName{
    color: #121c79;
    font-size: x-large;
    font-style: bold;
}