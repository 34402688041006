*{
    font-family: sans-serif;
}

.option{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.ans{
    margin-top: 20px;
    display: flex;
}
.checkbox{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.ques{
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
@media screen and (max-width: 600px) {
    .checkbox{
        margin-top: 10px;
    }
}