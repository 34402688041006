.logBtn{
    background-color: #121c79;
    color: white;
    border-radius: 5px;
    width: 100px;
    border: 2px #121c79 solid;
    font-weight: bold;
    float:right;
    margin-right: 54px;
}
.logBtn:hover{
    color: white;
}

#content{
    background-image: url("https://recstudentportal.com/gate.jpg");
    /* 
    -webkit-filter: blur(8px); */
    filter: blur(8px);
    /* Full height */
    transition: 0.5s;
    height: 100vh;
    width:100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
#content:hover{
filter: blur(0px);
}
.googlebtn{
    float: left;
    margin-left: 54px;
    
}
.LoginFrom{
    overflow: hidden;
    position: relative;
    top: 150px;
    padding-top: 30px;
    padding-bottom: 40px;
    height: auto;
    margin-top: 30px;
    margin-bottom: 20px;
    background-color: #fff;
    width: 80%;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px;
    border: 2px #f2f2f2 solid;
    z-index: 1;
    margin-top: -100vh;
    transition: 1s;
}
.LoginFrom:hover{
    box-shadow: 1px 1px 10px 1px grey;
}
.head p{
    /* float: left; */
    text-align: justify;
    margin-left: 55px;
    margin-top: -15px;
}
.head .spanlogin span{
    font-size: 40px;
}
.head .spanlogin{
    margin-top: -10px;
    float: left;
    margin-left: 55px;
}
body{
    overflow-x: hidden;
    
}
hr{
    width: 90%;
}
@media only screen and (max-width: 500px){
    .LoginFrom{
        position: relative;
    }
    .LoginFrom input{
        position: relative;
        right: 18px;
        height: 300px;
        width: 80%;
        margin: 0;
    }
    .head p {
        /* float: left; */
        text-align: justify;
        margin-left: 30px;
    }
    .head .spanlogin{
        margin-left: 30px;
    }
    .logBtn{
        margin-right: 35px;
        width: 80%;
        margin-top: -20px;
    }
    .googlebtn{
        margin-left: 25px;
        margin-top: 20px;
        width: 80%;
        text-align: center;
    }
}

.loginError{
    color: red;
}


#L{
    animation: animateu 2s;
}

@keyframes animateu {
    0% {
        margin-left: 90%;
        color: #FFFFFF;
    }

    25% {

        color: #FFFFFF;
    }

    100% {
        color: black;
    }
}

#O {
    animation: animated 2.5s;
}

@keyframes animated {
    0% {
        margin-left: 90%;
        color: #FFFFFF;
    }

    25% {

        color: #FFFFFF;
    }

    50% {
        color: #FFFFFF;
    }

    100% {
        color: black;
    }
}

#G {
    animation: animatea 3s;
}

@keyframes animatea {
    0% {
        margin-left: 90%;
        color: #FFFFFF;
    }

    25% {

        color: #FFFFFF;
    }

    50% {
        color: #FFFFFF;
    }

    100% {
        color: black;
    }
}

#I {
    animation: animatea1 3.5s;
}

@keyframes animatea1 {
    0% {
        margin-left: 90%;
        color: #FFFFFF;
    }

    25% {

        color: #FFFFFF;
    }

    50% {
        color: #FFFFFF;
    }

    100% {
        color: black;
    }
}

#N {
    animation: animaten 4s;
}

@keyframes animaten {
    0% {
        margin-left: 90%;
        color: #FFFFFF;
    }

    25% {

        color: #FFFFFF;
    }

    50% {
        color: #FFFFFF;
    }

    100% {
        color: black;
    }
}
