.tab{
	display: flex;
    justify-content: space-around;
    /* height: 100vh; */
}
.tab table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
.tab th, td {
  padding: 10px;
}


.header {
  padding: 15px;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}
.title {
  font-size: 30px;
}
.title span:nth-child(1) {
  background: linear-gradient(
    to right,
    #47484ec7,
    #276fad,
    #e24306db,
    #3e3a7ffa,
    #3b3952fa
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.title span:nth-child(2) {
  color: grey;
  padding-left: 10px;
}