.AppFeatureBox{
    margin:0;
    padding: 5px;
    width: 100%;
    background-color: #121c79;
    color: white;
    height: auto; 
    font-family: 'Open Sans', sans-serif ;
    text-align: center;
}

.appPara{
    margin: 40px;
    font-size: large;
}
.PlayDiv{
    margin-top: 40px;
    margin-bottom: 5px;
}

.Play{
    margin-top: 10px;
    height: 60px;
}

@media only screen and (max-width: 445px) {

    .AppFeatureBox{
        padding: 0;
    }

}
.FeatureBox{
    display: inline-block;
    margin-top: 2%;
    color: white;
    width: 200px;
    height: 140px;
    padding: 6px;
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 5px;
    border: white 2px solid;
}
.FeatureBox:hover{
    position: static;
    display: inline-block;
    margin-top: 2%;
    color: #121c79;
    background-color: white;
    width: 180px;
    height: 140px;
    padding: 6px;
    margin-left: 9px;
    margin-right: 9px;
    border-radius: 5px;
    border: white 2px solid;
    transition: 0.5s;
}
#sPara{
    font-size: small;
    color: white;
}
.FeatureBox:hover #sPara{
    font-size: small;
    color:#121c79; ;
}


@media only screen and (max-width: 768px) {
    .FeatureBox{
        width: 180px;
    }
}
