hr{
    width: 100%;
}
.hr1{
   opacity: 0;
}

@media only screen and (max-width: 445px) {

    .hr1{
        opacity: 1;
    }

}