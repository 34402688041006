.Cofounders{
    margin: 20px;
    padding: 20px;
}

.TheFounder{
    color: #121c79;
    margin-top: 30px;
}

