.footer{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.udaan{
  background: linear-gradient(
    to right,
    #47484ec7,
    #276fad,
    #e24306db,
    #3e3a7ffa,
    #3b3952fa
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  /* padding-right: 2px; */
  font-weight: bold;
}