.bg {
    /* background: #fceeb5; */
    font-family: Abel, Arial, Verdana, sans-serif;
    height: 100%;
    background-color: black;
    
}
.win{
    font-size: 6rem;
    font-family: sans-serif;
    font-weight: bold;
    background: linear-gradient(to right, #4bc0c8, #c779d0, #feac5e);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-align: center;
    
}