.uploadRes{
    padding: 8px;
    width: 99%;
    overflow: auto;
}
.formControl {
    margin: 10px !important;
    min-width: 90px !important;
    max-width: 200px;
}
.lastFiveRes{
    width: 99%;
    overflow: auto;
    padding: 16px;
    margin-top: 10px;
}

@media only screen and (max-width:460px){
    .uploadRes{
        width: 65%;
    }
    .lastFiveRes{
        width: 65%;
    }
    
}