
  .card
      {
          position: relative;
          width: 300px;
          height: 400px;
          margin: 10px auto;
          overflow: hidden;
          border-radius: 20px;
          transition: 0.5s;
          animation: animate 5s linear 1;
      }
      
      .card .img
      {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 350px;
          transition: 0.5s;
  
      }
      .card:hover
      {
          box-shadow: 0 40px 80px rgba(0,0,0,.5);
          background: white;
      }
      .card:hover .img
      {
          cursor: pointer;
          width: 120px;
          height: 120px;
          margin-left: 90px;
          margin-top: 50px;
          border-radius: 50%;
          overflow: hidden;
      }
      .card .img img{
          width: 100%;
          height: 100%;
    }
    .card .content
      {
          position: absolute;
          width: 100%;
          height: 60%;
          bottom: -37%;
          padding: 20px;
          box-sizing: border-box;
          text-align: center;
          transition: 0.5s
      }
      .card:hover .content
      {
          bottom: 0;
      }
      
      .card .content h2
      {
          font-size: 20px;
          color: black;
      }
      
      .card .content h2 .pos
      {
          display: inline-block;
          background: linear-gradient(to right,#47484ec7,#276fad,#e24306db,#3e3a7ffa,#3b3952fa);
		-webkit-text-fill-color:transparent;
		-webkit-background-clip:text;
          font-size: 20px;
      }
      .card .content ul{
          position: relative;
          list-style: none;
          margin-left: -14px;
          bottom:0px;
      }
      .card .content ul li a
      {
          position: relative;
          display: block;
          width: 30px;
          height: 30px;
          line-height: 25px;
          text-align: center;	
          float: left;
          border-radius: 50%;
          padding: 5px;
          margin: 0 5px;
          color: white;
      
          transition: 1s;
      }
      .card .content ul li a:hover{
          
          position: relative;
          display: block;
          width: 30px;
          height: 30px;
          line-height: 25px;
          text-align: center;
          background: #fff;
          margin: 0 5px;
          border-radius: 50%;
          transform: rotateZ(360deg);
          font-size: 20px;
  
      }
      .card .content ul li a:hover .fa-facebook-f
      {
          color: #3b5999;
      }
      .card .content ul li a:hover .fa-phone
      {
          
          color: #25D366;
      }
      .card .content ul li a:hover .fa-envelope
      {
          color: #dd4b39;
      }
      .card .content ul li a:hover .fa-linkedin-in
      {
          color: #0077B5;
      }
      .card .content ul li a:hover .fa-github
      {
        line-height: 15px;
          color:#211F1F;
      }