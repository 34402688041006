.NavigationItem{
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
    
}

.NavigationItem a{
    color: #8f5c2c;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.NavigationItem a:hover,
.NavigationItem a:active{
    
    color: #40A4c8;
}

@media (min-width:500px){
    .NavigationItem{
        margin: 0;
        width: auto;
        display: flex;
        height: 100%;
        align-items: center;
    }

    .NavigationItem a{
        color: #fff;
        height: 100%;
        padding: 16px 16px;
        border-bottom: 4px solid transparent;
    }
    
    .NavigationItem a:hover,
    .NavigationItem a:active{
        background-color: white;
        /* border-bottom: 1px solid #40A4c8; */
        color: #121c72;
        box-shadow: 1px 1px 10px 1px grey;
        
    }
}