.card {
  position: relative;
  width: 250px;
  height: 350px;
  margin: 10px auto;
  overflow: hidden;
  border-radius: 20px;
  transition: 1s;
  animation: animate 5s linear 1;
  box-shadow: 0 0px 10px rgb(0 0 0 / 50%);
}

.card .img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 285px;
  transition: 0.5s;
}
.card:hover {
  box-shadow: 0 40px 80px rgba(0, 0, 0, 0.5);
  background: white;
}
.card .img {
  cursor: pointer;
  width: 120px;
  height: 120px;
  margin-left: 65px;
  margin-top: 50px;
  border-radius: 50%;
  overflow: hidden;
}
.card .img img {
  width: 100%;
  height: 100%;
}
.card .content {
  position: absolute;
  width: 100%;
  height: 150px;
  bottom: -24%;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  transition: 0.5s;
}
.card .content {
  bottom: 0;
}

.card .content h2 {
  font-size: 20px;
}

.card .content h2 .pos {
  display: inline-block;
  /* background: linear-gradient(
    to right,
    #47484ec7,
    #276fad,
    #e24306db,
    #3e3a7ffa,
    #3b3952fa
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text; */
  font-size: 20px;
}

@media screen and (max-width: 600px) {
  .card {
	  height: 300px;
	  box-shadow: 0 0px 10px rgb(0 0 0 / 50%);
	}
	.card:hover{
	  box-shadow: 0 0px 10px rgb(0 0 0 / 50%);

  }
  .card .img {
	  cursor: pointer;
  width: 120px;
  height: 120px;
  margin-left: 65px;
  margin-top: 20px;
  border-radius: 50%;
  overflow: hidden;
  }
  img:hover{

  }
  .card:hover .img{
	  margin-top: 20px;
  }
  .card .content {
	  height: 145px;
  bottom: 0%;
  }
}
.circle{
  width: 10px;
    height: 10px;
    /* background: #04f504; */
    float: right;
    /* border-radius: 50%; */
    margin: 10px;
    margin-right: 30px;
}