.inputType{
    margin-top: 20px;
    width: 100%;
    
}

.inputType input{
    background-color: #f2f2f2;
    border-radius: 3px;
    height: 41px;
    /* background: #f2f2f2; */
    box-shadow: none !important;
    border: none;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    outline:0;
    appearance: none;
    -webkit-appearance: none;
    width: 80%;

}
input:focus {outline:none!important;}
@media only screen and (max-width: 500px) {
    .inputType{
        margin-left: 15px;
    }
}