@import url("https://fonts.googleapis.com/css?family=Abel");




.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.card {
    margin-top: 10px;
    width: 450px;
    height: 250px;
    background-color: #fff;
    background: linear-gradient(#f8f8f8, #fff);
    box-shadow: 0 8px 16px -8px rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    align-content: center;
    
}

.card h1 {
    text-align: center;
}

.card .additional {
    position: absolute;
    width: 150px;
    height: 100%;
    background: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
    
    transition: width 0.4s;
    overflow: hidden;
    z-index: 2;
}

.card .green .additional {
    background: linear-gradient(#92bca6, #a2ccb6);
}

.card:hover .additional {
    width: 100%;
    border-radius: 0 5px 5px 0;
}

.card .additional .user_card {
    width: 150px;
    height: 100%;
    position: relative;
    float: left;
}

.card .additional .user_card::after {
    content: "";
    display: block;
    position: absolute;
    top: 10%;
    right: -2px;
    height: 80%;
    border-left: 2px solid rgba(0, 0, 0, 0.025);
}

.card .additional .user_card .level,
.card .additional .user_card .points {
    top: 15%;
    color: #fff;
    text-transform: uppercase;
    font-size: 0.75em;
    font-weight: bold;
    background: rgba(0, 0, 0, 0.15);
    padding: 0.125rem 0.75rem;
    border-radius: 100px;
    white-space: nowrap;
}

.card .additional .user_card .points {
    top: 85%;
}

.card .additional .user_card img {
    margin-top: 65px;
    width: 120px;
    height: 120px;
    border-radius: 10px;
    margin-left: 0;
    /* border: 1px red solid; */
}

.card .additional .more_info {
    width: 300px;
    float: left;
    position: absolute;
    left: 150px;
    height: 100%;
}

.card .additional .more_info h1 {
    color: #fff;
    margin-bottom: 0;
}

.card.green .additional .more_info h1 {
    color: #224c36;
    font-size: 2 rem;
}

.card .additional .coords {
    margin: 0 1rem;
    color: #fff;
    font-size: 1rem;
}

.card.green .additional .coords {
    color: #325c46;
}

.card .additional .coords span+span {
    float: right;
}

.card .additional .stats {
    font-size: 2rem;
    display: flex;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
    top: auto;
    color: #fff;
}

.card.green .additional .stats {
    color: #325c46;
}

.card .additional .stats>div {
    flex: 1;
    text-align: center;
}

.card .additional .stats i {
    display: block;
}

.card .additional .stats div.title {
    font-size: 0.75rem;
    font-weight: bold;
    text-transform: uppercase;
}

.card .additional .stats div.value {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.5rem;
}

.card .additional .stats div.value.infinity {
    font-size: 2.5rem;
}

.card .general {
    width: 300px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    box-sizing: border-box;
    padding: 1rem;
    padding-top: 0;
}

.card .general .more {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    font-size: 0.9em;

}

.card .general .more .cong_name {
    background: linear-gradient(to right,
            #47484ec7,
            #276fad,
            #e24306db,
            #3e3a7ffa,
            #3b3952fa);
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

@media screen and (max-width: 600px) {
   .card{
       width: 340px;
       margin: 10px;
       
   }

   .card .additional .more_info {
       width: 200px;
       left: 130px;
   }
   .card .additional .user_card img {
       margin-top: 76px;
       width: 90px;
       height: 90px;
       border-radius: 50%;
       margin-left: -10px;
   }
   .card .additional {
       width: 120px;
   }
   .card .additional .user_card {
       width: 120px;
   }
   .card .additional .coords {
       margin: 0 0rem;
   }
  .card .general{
      width: 236px;
      font-size: 14px;
  }
  .card .general .more{
      font-size: 12px;
  }
  .card .additional .stats {
    font-size: 1rem;
    display: flex;
    position: absolute;
    bottom: 1rem;
    left: 0;
    right: 0;
    top: auto;
    color: #fff;
}
}
