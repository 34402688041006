.TeamInfo{
    background-color: #121c79;
    color: white;
    width: 100%;
    margin: 0;
}
.Heading{
    margin: auto;
    font-size:50px;
    font-style: bold;
    font-family: 'Poppins', sans-serif;
    font-style: 12;
    /* background: linear-gradient(to right,#16A085,#F4D03F);
		-webkit-text-fill-color:transparent;
    -webkit-background-clip:text; */
    color:#ffffff;
}

.TeamPara{
    padding: 70px;
    font-family: 'Poppins', sans-serif;
    font-style: 12;
    line-height: 1.5rem;
    letter-spacing: 0.05rem;
    /* background: linear-gradient(to right,#47484ec7,#276fad,#e24306db,#3e3a7ffa,#3b3952fa);
		-webkit-text-fill-color:transparent;
    -webkit-background-clip:text; */
    color: #fff;

}