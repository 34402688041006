.NavigationItems{
    margin:0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    height: 100%;
    flex-flow: column;
}
a a:hover{
    text-decoration: none;
}

a:hover{
    text-decoration: none;
}

@media (min-width: 500px){
    .NavigationItems{
        flex-flow: row;
    }
}