
.date{
width: 100%;
height: 300px;
margin-top: 200px;
}
.from label{
  float: left;
  font-size: 30px;
  font-weight: bold;
}
.from select{
      margin-top: 23px;
      float: left;
      margin-left: -74px;
      border: none;
      background: #f2f2f2;
      padding: 6px;
      border-radius: 5px;
}
.to label {
float: left;
font-size: 30px;
font-weight: bold;
    margin-left: -72px;
}
.to select{
  margin-top: 23px;
  float: left;
  margin-left: -74px;
  border: none;
  background: #f2f2f2;
  padding: 6px;
  border-radius: 5px;
}

.sec1 .btn {
  outline: none;
      width: 155px;
      height: 35px;
      margin-top: 56px;
      float: left;
      margin-left: -154px;
      background: #3199f7;
      border-radius: 5px;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
      border: none;
      /* border: 1px #3199f7 solid; */
      color: white;
}
.notice{
  margin-top: 50px;
  border-radius: 10px;
  width: 90%;
  height: 550px;
  /* background-color: white; */
  border:1px solid grey;
}
.scroll{
  background-color: white;
  overflow: auto;
  width: auto;
  height: 460px;
}
.notices{
  margin-top: 20px;
  width: 100%;
  height: 30px;
  text-align: center;
}
.openbtn a{
  text-decoration: none;
  color: black;
}
 .openbtn{
   float: right;
   margin-right: 20px;
   background: #F2F2F2;
   border-radius: 50px;
   width: 83px;
   height: 30px;
   font-style: normal;
   font-weight: bolder;
   font-size: 20px;
   line-height: 30px;
   color: black;
   margin-top: 10px;
 }
.tp{
  float: left;
  margin-left:20px;
  margin-top: 16px;
  font-weight: bold;
}
.title2{
  color: #121c72;
}
.title1{
  text-align: center;
  color: white;
}
@media screen and (max-width: 600px) {
 .notice{
   margin: 0px;
   width: 95%;
   margin-left:2% ;
 }
 .date{
   margin: 0;
   padding: 0;
   width: 0;
   /* height: 0; */
   height: auto;
 }
 .from label,.to label{
   font-size: 25px;
 }
 .from select,.to label,.to select,.sec1 .btn{
   margin: 0;
 }
 .to label{
   margin-top: 10px;
 }
 .sec1 .btn{
   width: 135px;
   margin-left: 30px;
   float: none;
 }
} 
