.outerDiv{
    padding: 30px;
    
    margin: 0;
}

.sucessImage{
   
    /* float: left; */
    margin: 10px;
}

.sucessImage img{
    height: 250px;
}
.successInfo{
    /* float: right; */
    margin: 20px;

}
.successInfo p{
  /* background: linear-gradient(to right,#30E8BF, #FF8235);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: #121c79;
  -webkit-background-clip: text;
  /* -webkit-text-fill-color: transparent; */
}
.successInfo h3{
    font-size: 50px;
    /* background: linear-gradient(to right,#f12711, #f5af19); */
    color: #121c79;
  -webkit-background-clip: text;
  /* -webkit-text-fill-color: transparent; */
}
.successInfo h4{
    
    /* background: linear-gradient(to right,#c31432, #240b36); */
    color: #121c79;
  -webkit-background-clip: text;
  /* -webkit-text-fill-color: transparent; */
}
