.theDiv{
    width: 90%; 
    margin: auto;
    padding: 16px;
    position: relative;
    bottom: 20px;
    margin-top: 72px;
    /* right: 4%; */
}




.CImage{
    border-radius: 8px;
    height:300px;
    margin-left: 5px;
    margin-right: 5px;
    box-shadow: 2px 2px 12px black;
}

.CImageBlock{
    /* width: 100%; */
    display: inline-block;
    /* margin: auto; */
    margin-left: 20px;
    margin-right: 20px;
    
}

.largeSlider{
    width: 100%;
}

.smallSlider{
    width: 100%;
    position: relative;
    right: 10px;
    display: none;
}

@media screen and (min-width: "499px"){
    .theDiv{
        width: 100%;
    }
    .cImages{
        height: 150px;
    }

}

@media only screen and (max-width: 890px) {
    .largeSlider{
        display: none;
    }
    .smallSlider{
        display: block;
    }
    .CImage{
        height: 270px;
        margin: auto;
    }
}


@media only screen and (max-width: 1110px) {
    .CImage{
        height: 250px;
    }

}

@media only screen and (max-width: 952px) {
    .CImageBlock{
            margin: 0px;
    }
    .CImage{
        height: 230px;
        width: 313px;
        margin: 0px;
    }

}

@keyframes fadeIn {
	0% { opacity: 0; }
	20% { opacity: 0; }
	60% { opacity: 1; }
	100% { opacity: 1; }
}

@keyframes mymove {
    from {left: 0px;}
    to {left: 200px;}
}

@keyframes animationFrames{
    0% {
      opacity:1;
      transform:  rotate(0deg) ;
    }
    100% {
      opacity:0;
      transform:  rotate(200deg) ;
    }
  }

.fadeInClass {
	animation-name: fadeIn;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	animation-timing-function: linear;
}
  
