.About{
    background-color: white;
    color: #121c79;
    /* height: 300px; */
}

.AboutHead{
    text-align: left;
    margin-left: 30px;
    margin-top: 20px;
}

.AboutLeft{
    
    text-align: left;
}

.AboutRight{
    
    text-align: left;
}
.AboutRow{
    margin: 0;
}
.blank{
   
}
.location{
    margin: 4px;
    padding: 20px;
    box-sizing: border-box;
    border: #121c79 solid 2px;
    border-radius: 3px;

}
.mail{
    margin: 4px;
    padding: 20px;
    box-sizing: border-box;
    border: #121c79 solid 2px;
    border-radius: 3px;
}

.someLeft{
    position: relative;
    right: 10px;
}

.LittleSpace{
    margin-left: 10px;
    margin-right: 10px;
}
.social{
    margin: 4px;
    padding: 20px;
    box-sizing: border-box;
    border: #121c79 solid 2px;
    border-radius: 3px;
}
.phone{
    margin: 4px;
    padding: 20px;
    box-sizing: border-box;
    border: #121c79 solid 2px;
    border-radius: 3px;
}

.increase { font-size: 50px; }