.Member{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 10px;
    border-radius: 5px;
    background-color: white;
    color: #121c79;
    width: 200px;
    height: 300px;
    overflow: hidden;
}

.Member img{
    border-radius: 10%;
    height: 150px;
    width: 120px;
    transition: 0.5s;
}

.icoImg img{
    position: relative;
    bottom: 8px;
}

.icoImg span{
    margin: 5px;
}

.img:hover{
    width: 190px;
    height: 290px;
    margin-left: -5px;
    margin-top: -5px;
    border-radius: 5px;
}
.img:hover {
    border-radius: 10%;
    height: 290px;
    width: 190px;
    transition: 0.5s;
    border-radius: 5px;
    margin-left: -5px;
    margin-top: -5px;

}