.studentPanel{
    padding: 16px;
}
.searchControl{
    margin-left: 8px !important;
    margin-right: 8px !important;
    max-width: 200px;
}
.memTable{
    width:99%;
    overflow: auto;
}

@media only screen and (max-width:1120px){
    .memTable{
        width: 94%;
    }
    
}

@media only screen and (max-width:810){
    .memTable{
        width: 700px;
    }
    
}


@media only screen and (max-width:600){
    .memTable{
        width: 400px;
    }
    
}
