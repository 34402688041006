.result__rank{
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
}
.result__rank1{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
@media screen and (max-width: 600px) {
    .result__rank1{
	justify-content: space-around;
	flex-wrap: wrap;
}
}
.header {
  padding: 30px;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}
.title {
  font-size: 30px;
}
.title span:nth-child(1) {
  background: linear-gradient(
    to right,
    #47484ec7,
    #276fad,
    #e24306db,
    #3e3a7ffa,
    #3b3952fa
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.title span:nth-child(2) {
  color: grey;
  padding-left: 10px;
}