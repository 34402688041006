/* .invalid{
	
	display: flex;
	align-items: center;
	justify-content: center;
} */
.invalid  p{
	display: flex;
	justify-content: center;
	
}
.invalid p span {
	padding-left: 5px;
}