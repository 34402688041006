.Toolbar{
    height: 56px;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #121c72;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing:  border-box;
    z-index: 90;
    border-bottom: solid 1px white;
}

.desktopHead{
    text-align: left;
    color: white;
}
.mobileHead{
    color: white;
    display: none;
}
.Toolbar nav{
    height: 100%;
    
}

@media (max-width:499px){
    .DesktopOnly{
        display: none;
    }
    .desktopHead{
        display: none;
    }
    .mobileHead{
        display: inline;
    }
}

