.overlay {
    min-height: 100vh;
    min-width: 100vw;
    /* background: rgba(255, 255, 255, 0.534); */
    /* backdrop-filter: blur(70px); */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}
.header {
  padding: 30px;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}
.title {
  font-size: 30px;
}
.title span:nth-child(1) {
  background: linear-gradient(
    to right,
    #47484ec7,
    #276fad,
    #e24306db,
    #3e3a7ffa,
    #3b3952fa
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.title span:nth-child(2) {
  color: grey;
  padding-left: 10px;
}
.mainBg {
    min-height: 100vh;
    width: 100%;
    position: absolute;
    /* top: 0; */
    right: 0;
    /* bottom: 0; */
    /* left: 0; */
    /* background: linear-gradient(35deg, #42359D 10%, #5A3EAF); */
    overflow: hidden;
}

.mainBg::after {
    content: '';
    height: 500px;
    width: 500px;
    border-radius: 252px;
    /* background: linear-gradient(to top, #EC6E95, #817CE3); */
    position: absolute;
    bottom: -50px;
    right: -20px;
}

.mainBg::before {
    content: '';
    height: 300px;
    width: 300px;
    border-radius: 252px;
    /* background: linear-gradient(to top, #EC6E95, #b17488); */
    position: absolute;
    top: -50px;
    left: -20px;
}

.modal {
    width: 60%;
    height: 70%;
    /* background-color: aliceblue; */
    /* padding: 50px; */
    /* border-radius: 20px; */
    /* box-shadow: 5px 5px 20px #0000003f; */
    animation: modal-anim 0.5s ease forwards;
}

@keyframes modal-anim {
    0% {
        transform: scale(0.3);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.tick {
    height: 100px;
    width: 120px;
    margin: auto;
}

.tick circle {
    stroke-dasharray: 1732;
    stroke-dashoffset: 1732;
    animation: circle-anim 0.5s ease forwards 0.5s;
}

@keyframes circle-anim {
    0% {
        stroke-dashoffset: 1732;
        stroke-opacity: 0;
        stroke: black;
    }
    100% {
        stroke-dashoffset: 0;
        stroke-opacity: 1;
        stroke: #4BC076;
    }
}

.tick path {
    stroke-dasharray: 693;
    stroke-dashoffset: 693;
    animation: path-anim 0.7s ease forwards 1s;
}

@keyframes path-anim {
    0% {
        stroke-dashoffset: 693;
        stroke-opacity: 0;
        stroke: black;
    }
    100% {
        stroke-dashoffset: 0;
        stroke-opacity: 1;
        stroke: #022e12;
    }
}

.text {
    font-family: sans-serif;
    text-align: center;
    font-size: 55px;
    color: transparent;
    -webkit-text-stroke: 2px #022e12;
    animation: text-anim 1s ease forwards 0.5s;
}

@keyframes text-anim {
    0% {
        color: transparent;
        -webkit-text-stroke: 2px #022e12;
    }
    100% {
        color: #4BC076;
        -webkit-text-stroke: unset;
    }
}

.btn-container {
    width: 60%;
    margin: 10px auto;
    margin-top: 20px;
}

.btn-container .btns {
    width: 100%;
    background-color: #817CE3;
    outline: none;
    border: none;
    border-right: 25px solid #42359D;
    font-family: squirk;
    color: white;
    font-size: 16px;
    letter-spacing: 3px;
    box-shadow: 2px 2px 0px #5A3EAF;
    transition: all 0.1s ease;
    font-weight: 100;
    animation: btn-anim 0.5s ease forwards 1.2s;
    opacity: 0;
}

@keyframes btn-anim {
    from {
        opacity: 0;
        transform: scale(0.5);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.btn-container .btns:active {
    box-shadow: none;
}

.supportText {
    text-align: center;
    font-weight: 500;
    color: #022e12;
}

@media only screen and (max-width: 600px) {
    .modal {
        width: 95%;
        /* padding: 20px; */
        /* padding-top: 50px; */
        /* padding-left: 12px; */
    }
    .text {
        font-size: 32px;
    }
    .btn-container {
        width: 80%;
    }
    .btn-container .btns {
        font-size: 14px;
    }
}
.overlay p{
	display: flex;
}
.redirect{
	    display: flex;
    justify-content: center;
    margin-top: 1rem;
}
.overlay p span{
	padding-left:5px ;
}