body {
    -youbkit-touch-callout: none; /* iOS Safari */
    -youbkit-user-select: none;   /* Chrome 6.0+, Safari 3.1+, Edge & Opera 15+ */
    -moz-user-select: none;    /* Firefox */
    -ms-user-select: none;        /* IE 10+ and Edge */
    user-select: none;  
    font-family: 'Poppins', sans-serif;          /* Non-prefixed version,
                                currently supported by Chrome and Opera */
}
.overlay {
  z-index: 11;
  position: absolute;
  width: 100%;
  height: 100vh;
  background: #fff;
  top: 0%;
  transition: 0.5s;
}
.overlay1 {
  /* top: -400%; */
  z-index: 11;
  position: absolute;
  width: 100%;
  height: 100vh;
  background: #fff;
  top: -400%;
  transition: 0.5s;
}
.header {
  padding: 30px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
.header1 {
  padding: 10px;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}
.title {
  font-size: 30px;
}
.title span:nth-child(1) {
  background: linear-gradient(
    to right,
    #47484ec7,
    #276fad,
    #e24306db,
    #3e3a7ffa,
    #3b3952fa
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.title span:nth-child(2) {
  color: grey;
  padding-left: 10px;
}
.quit {
  font-size: 30px;
}
.details {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  padding-bottom: 10px;
}
.question {
  /* cursor: none; */
  padding: 30px;
  padding-top: 10px;
}
.width {
  width: 100%;
}
.option {
  width: 100%;
  display: flex;
  padding: 30px;
}
.option__div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  align-items: center;
}
.quit1 {
  visibility: collapse;
  width: 0px;
  height: 0px;
}
.timer p{
  display: initial;
}
.time__progress{
  animation: animate_text_colour 91s linear;  /*Time Change*/
  color: red;
}

.circle__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 30px;
}
.circles {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 1px grey solid;
  margin: 30px;
  text-align: center;
  line-height: 1.5;
}
.circles h3 {
  margin-top: 35px;
}
.circular {
  height: 150px;
  width: 150px;
  position: relative;
  margin:30px;
}
.circular .inner,
.circular .outer,
.circular .circle {
  position: absolute;
  z-index: 6;
  height: 100%;
  width: 100%;
  border-radius: 100%;
  /* box-shadow: inset 0 1px 0 rgba(0,0,0,0.2); */
}
.circular .inner {
  top: 50%;
  left: 50%;
  height: 130px;
  width: 130px;
  margin: -65px 0 0 -65px;
  background-color: #fff;
  border-radius: 100%;
  /* box-shadow: 0 1px 0 rgba(0,0,0,0.2); */
}
.circular .circle {
  z-index: 1;
  box-shadow: none;
}
.circular .numb {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  font-size: 30px;
  font-weight: 500;
  
}
.circular .numb p{
	animation: animate_text_colour 91s linear; /*Time Change*/
  color: red;
}
@keyframes animate_text_colour {
  0% {
	  color: rgb(44, 241, 54);
  }
  50% {
	  color: yellow;
  }
  75% {
	  color: orange;
  }
  100% {
	  color: red;
  }
}
.circular .bar {
  position: absolute;
  height: 100%;
  width: 100%;
  /* background: rgb(44, 241, 54);; */
  -webkit-border-radius: 100%;
  clip: rect(0px, 150px, 150px, 75px);
}
.circular .circle .bar .progress {
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-border-radius: 100%;
  clip: rect(0px, 75px, 150px, 0px);
}
/* .circular .circle .bar .progress,
.dot span {
  /* background: red; 
} */

.circle{
	border: none;
	animation: animate_colour 91s linear; /* Time Change */
  
}
@keyframes animate_colour {
  0% {
	  color: rgb(44, 241, 54);
    background: rgb(44, 241, 54);
  }
  50% {
	  color: yellow;
    background: yellow;
  }
  75% {
	  color: orange;
    background: orange;
  }
  100% {
	  color: red;
    background: red;
  }
}
.circular .circle .left .progress {
  z-index: 1;
  animation: left 45s linear both;
  animation-delay: 46s;             /* Time Change */
  transform: rotate(0deg);
  /* background-color: red; */
}
@keyframes left {
  0%{
	background: white;
}
75%{
background: white;
}
  100%{
    transform: rotate(-180deg);
   background: white;
  }
  /* 100% {
    transform: rotate(180deg);
  } */
}
.circular .circle .right {
  z-index: 3;
  transform: rotate(180deg);
}
.circular .circle .right .progress {
  animation: pro__right 46s linear both;   /*Time Change*/
  
}

@keyframes pro__right {
  0%{
	background: white;
}
25%{
background: white;
}
80%{
	background: white;
}
  100%{
	 background: white;
    transform: rotate(-180deg);
  }
  
}
.circular .circle .dot {
  z-index: 2;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50%;
  height: 10px;
  margin-top: -5px;
  animation: dot 91s linear both;   /*Time Change*/
  transform-origin: 0% 50%;
}
.circular .circle .dot span {
  position: absolute;
  right: 0;
  width: 10px;
  height: 10px;
  border-radius: 100%;
}
@keyframes dot {
  0% {
    transform: rotate(270deg);
  }
  50% {
    transform: rotate(90deg);
    z-index: 4;
  }
  100% {
    transform: rotate(-90deg);
    z-index: 4;
  }
}
@media screen and (max-width: 600px) {
  .quit {
    visibility: collapse;
    width: 0px;
    height: 0px;
  }
  .header {
    justify-content: center;
  }
  .question,
  .details,
  .header,
  .option {
    padding: 10px;
  }
  .details {
    margin-top: 45px;
  }

  .quit1 {
    visibility: inherit;
    width: 100%;
    /* margin-top: 90px;
    display: flex;
    height: 100%; */
    justify-content: center;
    

  }
  .circles h3 {
  margin-top: 13px;
}
.circular {
  height: 75px;
    width: 75px;
    position: relative;
    margin: 0px;
}
  .quit1 > div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .circle__content {
    /* display: contents;
    visibility: collapse;
    width: 0px;
    height: 0px;
        margin-top: -1000px; */
        padding: 0;
  }
  .circles {
    width: 75px;
    height: 75px;
    margin: 0px;
  }
  .circles h3{
    font-size: 1rem;

  }
  .circles h1{
    font-size: 1.5rem;
  }
  .circular .inner{
    height: 65px;
    width: 65px;
    margin: -32.5px 0px 0 -32.5px;
    background-color: #fff;
    border-radius: 100%;
  }
  .circular .numb p{
    margin-top: 10px;
  }
  .details {
    margin-top: 15px;
  }

}