.NewsFeedTite{
    margin-bottom: 10px;
    margin-left: 4px;
    margin-right: 4px;
    display: inline-block;
    padding: 3px;
    width: 300px;
    height: 400px;
    overflow: auto;
}
.tileImg{
    width: 100%;
   
}
.colorBlue{
    color: #121c72;
}

.stickyButton{
    position: sticky; 
    bottom: 0;
}
