/* 
.card{
    
    border: 1px solid white;
    border-radius: 5px;
    margin-top: 50px;
    height: 60px;

}
.card p{
    font-size: 25px;
    color: white;
}
.topic{
    float: left;
    margin-left: 15px;
    color: white;
    font-size: 25px;
    margin-top: -18px;
    display: block;
    background: black;
}
.data{
    margin-top: -12px;
    float: left;
    margin-left: 15px;
}
@media only screen and (max-width: 500px) {
    .card {

        border: 1px solid white;
        border-radius: 5px;
        margin-top: 20px;
        height: 52px;

    }
    .data{
        margin-top: -15px;
    }
    .topic{

    }
} */

.card {
    width: 250px;
    height: 50px;
    /* background: #dfe6e9; */
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 0 70px; */
    font-size: 18px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: .3s linear;
    font-family: sans-serif;
    margin-top: 35px;
    /* background: white; */
    background:#121c79;
    border-radius: 5px;
    box-shadow: 1px 1px 10px 1px white;
}


.card span {
    position: absolute;
    width: 100%;
    /* height: 100%; */
    /* background: #2d3436; */
    /* color: #121c79; */
    color:white;
    text-align: center;
    line-height: 80px;
    z-index: 999;
    transition: .2s linear;
    border-radius: 14px;

}

.card:hover span {
    transform: translateX(-100%);
}

.card p {
    flex: 1;
    /* font-size: 26px; */
    /* color: #121c79; */
    color: black;
    font-weight: bold;
    text-align: center;
    /* transform: translateX(-100%); */
    opacity: 0;
    transition: .2s linear;
    text-decoration: none;
    margin-top: 11px;

}

.card:hover p {
    opacity: 1;
    transform: translateX(0);
}

.card:hover{
    background: none;
    border-radius: 5px;
    box-shadow: none;
}
@media screen and (max-width:600px){
    
    .card {
        margin-top: 10px;
    }
}
