.row {
  margin-right: 0px;
  margin-left: 0px;
}

.overlay {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100vh;
  background: #fff;
  top: 0%;
}

.overlay h1 {
  position: absolute;
  top: 40%;
  left: 52%;
  transform: translate(-50%, -50%);
  font-size: 80px;
  font-weight: bolder;
  letter-spacing: 12px;
  background: linear-gradient(
    to right,
    #47484ec7,
    #276fad,
    #e24306db,
    #3e3a7ffa,
    #3b3952fa
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.overlay h2 {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  font-weight: bolder;
  letter-spacing: 12px;
}
.sec1 {
  height: 100vh;
  overflow: hidden;
}
.title {
  float: left;
  padding: 30px;
  font-size: 30px;
}
.title span:nth-child(1) {
  background: linear-gradient(
    to right,
    #47484ec7,
    #276fad,
    #e24306db,
    #3e3a7ffa,
    #3b3952fa
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.title span:nth-child(2) {
  color: grey;
  padding-left: 10px;
}

.center {
  /* width: 100%;
  height: 100%; */
  /* display: flex; */
  text-align: initial;
  margin-top: 170px;
  position: relative;
  padding: 30px;
  color: red;
}

.center div {
  position: relative;
  height: 100px;
  margin: -30px 0;
  z-index: 2;
  transform: skewY(-5deg);
  justify-content: center;
  align-items: center;
}

.center div:nth-child(2),
.center div:nth-child(4) {
  transform: skewY(4.2deg);
  z-index: 1;
}

.center div:nth-child(4) {
  transform: skewY(12deg);
  transform-origin: left;
  top: -32px;
}

.center div:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
}

.center div:nth-child(1):before,
.center div:nth-child(3):before {
  background: linear-gradient(-160deg, #ff0058, #673ab7);
  transform: scaleX(0);
}

.center div:nth-child(2):before,
.center div:nth-child(4):before {
  background: linear-gradient(-20deg, #ff0058, #38009c);
  transform: scaleX(0);
}

.center div:nth-child(1):before {
  animation: animate 0.5s linear forwards;
  transform-origin: right;
  animation-delay: 2s;
}

.center div:nth-child(2):before {
  animation: animate 0.5s linear forwards;
  transform-origin: left;
  animation-delay: 2.5s;
}

.center div:nth-child(3):before {
  animation: animate 0.5s linear forwards;
  transform-origin: right;
  animation-delay: 3s;
}

.center div:nth-child(4):before {
  animation: animate 0.5s linear forwards;
  transform-origin: left;
  animation-delay: 3.5s;
  width: 60%;
  left: 0;
}

@keyframes animate {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

.center div:nth-child(1):after,
.center div:nth-child(3):after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: rgba(255, 255, 255, 0.2);
}

.center div h1 {
  /* font-size: 10px; */
  position: relative;
  margin: 0;
  padding: 0;
  z-index: 10;
  opacity: 0;
  color: #fff;
  text-align: center;
}

.center div:nth-child(1) h1 {
  padding: 13px;
  animation: fadeText 0.5s linear forwards;
  animation-delay: 2.5s;
  font-size: 60px;
}

.center div:nth-child(3) h1 {
  animation: fadeText 0.5s linear forwards;
  animation-delay: 3s;
  font-size: 90px;
}

@keyframes fadeText {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.date {
  display: flex;
  align-items: center;
  justify-content: center;
}
.details {
  animation: animatd;
  animation-duration: 3.8s;
  transition: 1s;
}
@keyframes animated {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.count {
  animation: animated;
  animation-duration: 5.6s;
  transition: 1s;
}
@keyframes animated {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.count {
  text-align: center;
}

.text {
  color: red;
}

.count > div {
  display: inline-block;
  width: 75px;
  height: 75px;
  border: 2px solid black;
  border-radius: 50px;
  font-size: 11px;
  color: black;
  text-align: center;
  overflow: hidden;
}

.count > div > span {
  display: block;
  font-size: 23px;
  margin-top: 14px;
  color: black;
}
.btn__div {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
@media screen and (max-width: 600px) {
  .center div:nth-child(2),
  .center div:nth-child(4) {
    transform: skewY(19.5deg);
    z-index: 1;
  }
  .center div:nth-child(4) {
    transform: skewY(25deg);
    transform-origin: left;
    top: -55px;
  }
  .center div:nth-child(1) h1 {
    font-size: 35px;
    padding-top: 29px;
  }
  .center {
    margin-top: 20px;
  }
  .title {
    float: initial;
    padding: 10px;
  }
  .sec1 {
    height: 100vh;
    overflow: hidden;
  }
  .details {
    /* margin-top: 50px; */
  }
  .details h2 {
    font-size: 20px;
  }
  .count > div {
    width: 60px;
    height: 60px;
    font-size: 9px;
  }
  .count > div > span {
    font-size: 12px;
  }
  .btn__div {
    margin-top: 5px;
  }
}
