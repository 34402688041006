.NoticePanel{
    margin-top: 30px;
    width: 99%;
    overflow: auto;
}

@media only screen and (max-width:460px){
    .NoticePanel{
        width: 94%;
    }
    
}