@import url('https://fonts.googleapis.com/css?family=Cabin&display=swap');

 .Features-title{
    font-family: 'Cabin', sans-serif;
    margin-bottom: 50px;
}

 .Features-title p{
    font-size: 1.7rem;
}
 .FeatureBox-box{
    height: 200px;
    width: 400px;
    border: 2px solid #121c79;
    display: inline-block;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 3px;
    margin-bottom: 50px;
}
 .FeatureBox-box p{
    color:#121c79;
    font-size: 1.3rem;
    position: relative;
    bottom: 60px; 
}

 .FeatureBox-box img{
    padding: 10px;
    height: 100px;
    position: relative;
    bottom: 65px;
    background-color: #121c79;
    border-radius: 70px;
}


 #sPara-p{
    color: #000;
    padding: 2px;
    font-size: 1rem;

}
@media screen and (max-width: 600px) {
    .FeatureBox-box{
        width: auto;
    }
}