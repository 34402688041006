.title{
	font-size: 6rem;
  font-family: sans-serif;
  font-weight: bold;
  background: linear-gradient(to right, #4bc0c8, #c779d0, #feac5e);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-align: center;
  margin-top: 20px;
}
.sec{
	height: 100vh;
	/* background-color: red; */
}
.udaan{
  background: linear-gradient(
    to right,
    #47484ec7,
    #276fad,
    #e24306db,
    #3e3a7ffa,
    #3b3952fa
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  /* padding-right: 2px; */
  font-weight: bold;
}

.card{
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
      transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
  padding: 14px 80px 18px 36px;
  /* width: 300px;
  height: 300px; */
  cursor: pointer;
  margin-top: 3vh;
}

.card:hover{
     transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.card h3{
  font-weight: 600;
}

.card img{
  position: absolute;
  top: 20px;
  right: 15px;
  max-height: 120px;
}

.card1{
  background-image: url(https://ionicframework.com/img/getting-started/ionic-native-card.png);
      background-repeat: no-repeat;
    background-position: right;
    background-size: 80px;
}

.card2{
   background-image: url(https://ionicframework.com/img/getting-started/components-card.png);
      background-repeat: no-repeat;
    background-position: right;
    background-size: 80px;
}

.card3{
   background-image: url(https://ionicframework.com/img/getting-started/theming-card.png);
      background-repeat: no-repeat;
    background-position: right;
    background-size: 80px;
}
.card__sec{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 80vh;
  margin-top: 5vh;
}
@media(max-width: 990px){
  .card{
    margin: 20px;
  }
  .sec{
	  height: auto;
  }
  .title{
    font-size: 3rem;
        margin-top: 25px;
  }
  .card__sec{
    height: auto;
    margin-top: 2vh;
  }
} 