/* .box{
    background-color: black;
    position: absolute;
}
.content{
    width: 100%;
    margin-top: -95vh;
    height: 90vh;
    position: relative;
    background-color: black;
    border-radius: 5px;
}
.content1{
    width: 100%;
    margin-top: -95vh;
    height: 90vh;
    position: relative;
    background-color: black;
    border-radius: 5px;
    z-index: 100;
    overflow: hidden;
}
.content:before,
.content:after {
    border-radius: 5px;
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    background: linear-gradient(45deg, violet, indigo, blue, green, yellow, orange, red, violet, indigo, blue, green, yellow, orange, red);
    background-size: 400%;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: 1;
    
}

.content:after {
    filter: blur(10px);
}
.content:before,
.content:after,.content:hover {
    animation: animate 20s linear infinite;
}
@keyframes animate {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 300% 0;
    }

    100% {
        background-position: 0 0;
    }
}
.sec1{
    border-right: 1px solid white;
    height: 90vh;
}
.sec1 img{
    margin-top: 50px;
    border-radius: 50%;
    width: 200px;
    height: 200px;
}
.logBtn {
    background-color: #121c79;
    color: white;
    border-radius: 5px;
    width: 100%;
    border: 2px #121c79 solid;
    font-weight: bold;
    float: right;
    /* margin-right: 54px; 
    margin-top: 50px;
}

.logBtn:hover {
    color: white;
}
.mobliediv{
    visibility: hidden;
}
@media only screen and (max-width: 500px) {
    .mobliediv {
        visibility: visible;
    }
    .content ,.content1{
            width: 95%;
            margin-left: 11px;
            height: 95vh;
            margin-top: -98vh;
    }
    .sec1 img{
        margin-top: 10px;
        width: 150px;
        height: 150px;
    }
    .sec2{
        visibility: hidden;
        /* visibility: ; 
    }
     .logBtn {
         margin-right: 0px;
         width: 100%;
         margin-top: 20px;
     }
     
} */
/* .img{ */
    /* background: #373B44;
    background: -webkit-linear-gradient(to left, #4286f4, #121c72);
    background: linear-gradient(to left, #4286f4, #121c72 ); */
    /* background-color: black; */

    /* background: #FEAC5E; */
    /* fallback for old browsers */

    /* background: -webkit-linear-gradient(to left, #4BC0C8, #C779D0, #FEAC5E); */
    /* Chrome 10-25, Safari 5.1-6 */

    /* background: linear-gradient(to left, #4BC0C8, #C779D0, #FEAC5E); */
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    /* background: #1488CC; */
    /* fallback for old browsers */

    /* background: -webkit-linear-gradient(to left, #2B32B2, #1488CC); */
    /* Chrome 10-25, Safari 5.1-6 */

    /* background: linear-gradient(to left, #2B32B2, #1488CC); */
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    /* width: 100%;
    height: 100vh; */
    
    /* margin-top: -2vh; */

/* } */
.img{
    background: linear-gradient(to right top,#48acf0,#03045e,#023e8a,#023e8a);
    width: 100%;
    height: 100vh;
}

.circle{
    /* width: 300px;
    height: 300px; */
   width:80%;
   min-height:20vh;
    

    /* background: #70e1f5;
    background: -webkit-linear-gradient(to left, #ffd194, #70e1f5);
    background: linear-gradient(to left, #ffd194, #70e1f5);
    background: #56CCF2; */

    background: white;
    background: linear-gradient(to right bottom, rgba(255,255,255,0.8),rgba(255,255,255,0.3));
    height: 18rem;
    width: 18rem;
    position: absolute;
    border-radius: 50%;

    /* fallback for old browsers */

    /* background: -webkit-linear-gradient(to left, #2F80ED, #56CCF2); */
    /* Chrome 10-25, Safari 5.1-6 */

    /* background: linear-gradient(to left, #2F80ED, #56CCF2); */
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    animation: roundmove 3s infinite ease-in;

}
.circle1{
    /* float: right;
    margin-top: 8vh; */
    top:1%;
    right:3%;
}
.circle2{
    margin-top: 71vh;
    /* bottom:-1%;
    left:1%; */
}
.box{
    position: relative;
    top: 88px;
    width: 100%;
    height: 530px;
    padding: 20px;
    margin-left:40px;
    margin-right:40px;
    border-radius: 5px;
    border: 1px solid gray;
    margin-top: -100vh;
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    /* background: rgba(243, 218, 218, 0.1); */
    background:linear-gradient(to right bottom, rgba(255,255,255,0.7),rgba(255,255,255,0.3)); ;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
    /* background-color: red; */

}
/* .sec1 { */
    /* border-left: 1px solid white;
    border-radius: 50%;
    height:600px; */
    
/* } */

.sec1 img {
    margin-top: 50px;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    background: white;
    /* border-radius: 5px; */
    box-shadow: 1px 1px 10px 1px white;
    /* box-shadow: 0px 1px 0px rgb(1, 1, 92),
    0px 2px 0px rgb(13, 44, 182),
    0px 3px 0px rgb(29, 60, 61),
    0px 4px 0px rgb(41, 85, 87),
    0px 5px 0px rgb(60, 125, 128),
    0px 6px 0px rgb(196, 208, 209),
    0px 7px 8px rgb(84, 118, 230); */
}
@keyframes roundmove{
    from{
       transform:rotateZ(360deg);
    }
    to{
        transform:rotateZ(-360deg);
    }
}
@media screen and (max-width: 600px) {
    .img{
        height: auto;
    }
    .box{
            top: 25px;
            height: auto;
        margin-top: 0px;
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
    }


}
